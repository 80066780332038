import React from 'react'
import { useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import { USER_SERVER } from '../Config';
import axios from 'axios';
import logo from '../../Icons/logo.svg'
import profileIcon from '../../Icons/profile.svg'
import logoutIcon from '../../Icons/logout-icon.svg'
import './header.css'

function Header() {
  const navigate = useNavigate();
  const user = useSelector(state => state.user);  
  const logoutHandler = () => {
    axios.get(`${USER_SERVER}/logout`).then(response => {
      if (response.status === 200) {
        navigate('/login');
      } else {
        alert('Log Out Failed')
      }
    }).catch(error =>{
      console.error("Error logging out");
    });
  };

  return (
    <header id="header" className="header fixed-top d-flex align-items-center">
        <div>
            <img src={logo} className="App-logo" alt="logo"></img>
        </div>
        <nav className="header-nav ms-auto align-items-center" style={{marginRight: '20px'}}>
          <div className="row">
            <div className="col-2" style={{paddingTop: '3px', marginRight:'10px'}}>
              <img src={profileIcon} alt="profileIcon"></img>
            </div>
            <div className="col-9">
              <div className="row">
                <Link style = {{paddingLeft:'1px',font: "Poppins", fontWeight:600, fontSize:'16px', color:'#00A2B9'}}>
                  {user && user.userData ? user.userData.name : 'getting user info...'} {user && user.userData ? user.userData.lastname: ''}
                </Link>
              </div>
              <div className="row dropdown-toggle" data-bs-toggle="dropdown" data-bs-offset="1,0" id = "emialID"
               style={{marginTop:'-8px', cursor:'pointer',font: "Poppins", fontWeight: 500, fontSize:'14px', color:'#A0AFC4'}}>
                  {user && user.userData ? user.userData.email : 'geetting email info...'}
              </div>
              <ul className="dropdown-menu" aria-labelledby='emailID'>
                <li className='dropdwon-item' style={{paddingInline:'15px'}}>
                  <img src = {logoutIcon} alt = "icon" style={{paddingInline:'10px'}}/>
                  <Link
                  onClick={logoutHandler}
                  style={{fontFamily:'Poppins', fontWeight:500, fontSize:'14px', color:'#A0AFC4'}}>
                    Log out
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
    </header>
  )
}

export default Header