import React from "react";
import { Routes, Route, Outlet} from "react-router-dom";
import Auth from './hoc/auth';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './App.css';
import Header from './components/Header/Header';
import SideBar from './components/SideBar/SideBar';
import DashBoard from './components/DashBoard/DashBoard';
import HomePage from "./components/HomePage/HomePage";
import LogInPage from "./components/LogInPage/LogInPage";
import EditData from "./components/EditData/EditData";
import RegisterPage from "./components/RegisterPage/RegisterPage";

function App() {
  return (
    // <BrowserRouter>
      <Routes>
        {/* Routes with Header and SideBar */}
        <Route element={<WithHeaderAndSidebar />}>
          <Route path="/" element={<AuthWrapper Component={HomePage} reload={true} />} />
          <Route path="/dashboard" element={<AuthWrapper Component={DashBoard} reload={true} />} />
          <Route path="/edit" element={<AuthWrapper Component={EditData} reload={true} />} />
          {/* <Route path="/dashboard" element={<DashBoard />} />
          <Route path="/edit" element={<EditData />} /> */}
        </Route>

        {/* Routes without Header and SideBar */}
        <Route path="/login" element={<LogInPage />} />
        <Route path="/register" element={<RegisterPage />} />
      </Routes>
    // </BrowserRouter>
  );
}

function WithHeaderAndSidebar() {
  return (
    <>
      <Header />
      <SideBar />
      <AuthWrapper Component={Outlet} reload={true} />
    </>
  );
}

function AuthWrapper({ Component, reload, adminRoute }) {
  const WrappedComponent = Auth(Component, reload, adminRoute);
  return <WrappedComponent />;
}
export default App;
