import React, {useState, useEffect} from 'react'
import Calendar from './Calendar';
import './inputForm.css'
import crossIcon from '../../../Icons/cross-icon.svg'
import axios from 'axios';
import { USER_SERVER } from '../../Config.js';

function InputForm(props) {
  const [report, setReport] = useState("");
  const [dropDownItem, setDropDownItem] = useState("");
  const [dropDownItems, setDropDownItems] = useState([]);
  const [cost, setCost] = useState("");
  const [volume, setVolume] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  function getYearMonth(date){
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // 1-based month

    return `${year}-${month}`;
  }

  useEffect(() =>{
    const orgID = '6710aef634e00f4f477a0086';
    axios.get(`${USER_SERVER}/getAllReports/${orgID}`,{ withCredentials: true }).then(
      response =>{
        if(response.data.success){
          const lastFiveReports = response.data.reports
          .filter(report => report.trim() !== '').slice(-5);
          setDropDownItems(lastFiveReports);
        }else{
          console.log("Message from getAllReports: ", response.data.message);
        }
      }
    ).catch(err => {
      console.error('Error fetching reports:', err);
    });
  },[])

  function handleAddEntry(){
    if(cost === "" || volume === ""|| selectedDate === ""){
      alert("please fill all field values")
    }else{
      const dataToSubmit = {
        cost : Number(cost),
        volume : Number(volume),
        yearMonth : getYearMonth(selectedDate),
        report : report
      }

      axios.post(`${USER_SERVER}/addMonthlyReport`, dataToSubmit, {withCredentials: true})
      .then(response => {
        if (response.data.success) {
          setCost("");
          setVolume("");
          setSelectedDate("");
          setReport("");
          setDropDownItems((prevReports) => {
            const updatedReports = [...prevReports, dataToSubmit.report];
            if (updatedReports.length > 5) {
              updatedReports.shift();
            }
            return updatedReports;
          });
          props.setChange(props.change+1);
          alert("Upload successful");
        } else {
          alert(`Upload failed: ${response.data.message}`);
        }
      })
      .catch(error => {
        console.error("Error:", error);
        alert("An error occurred, please try again.");
      });
    }
  }


  return (
    <>
    <div className="form-header">
        <h3 className='form-heading'>Adding entry</h3>
        <button 
        className={cost === "" || volume === ""|| selectedDate === "" ?'button-add' : 'button-add active'} 
        onClick={handleAddEntry} disabled = {cost === "" || volume === ""|| selectedDate === ""}
        >
          <b>+</b>
          <span style={{marginInlineStart:'20px'}}>
            Add new entry
          </span>
        </button>
    </div>
    <div className="row" style={{margin:'2px'}}>
      <div className="col-lg-4">
        <div className="row" style={{display:'flex', justifyContent:'center'}}>
          <div className="card" style={{paddingInline:'0px', height:'200px', width:'95%', marginBlock:'10px'}}>
            <div className="card-body">
            <h3 className='inputCardTitle'>Water used</h3>
              <div className="row mb-3">
                <label htmlFor="waterVol" className="inputLabel col-sm-4 col-form-label"
                style={{display:'flex', justifyContent:'flex-end'}}>
                  {'Cubic meter (m³)'}
                </label>
                <div className="col-sm-5">
                  <input type="number" className="form-control" id="waterVol"
                  value={volume}
                  onChange={(event) => setVolume(event.target.value)}/>
                </div>
                <label htmlFor="waterVol" className="inputLabel col-sm-3 col-form-label">per month</label>
              </div>

              <div className="row mb-3">
                <label htmlFor="waterCost" className="inputLabel col-sm-4 col-form-label"
                style={{display:'flex', justifyContent:'flex-end'}}>
                  {'Cost (¥)'}
                </label>
                <div className="col-sm-5">
                  <input type="number" className="form-control" id="waterCost"
                  value={cost}
                  onChange={(event) => setCost(event.target.value)}/>
                </div>
                <label htmlFor="waterCost" className="inputLabel col-sm-3 col-form-label">per month</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="row" style={{display:'flex', justifyContent:'center'}}>
           <h3 className='inputCardTitle' style={{marginBlock: '10px'}}> Date of entry</h3>
          <Calendar selectedDate = {selectedDate} setSelectedDate = {setSelectedDate}/> 
        </div>
      </div>
    </div>
    <div className="row" style={{marginTop:'20px',marginInline:'2px', marginBottom:'130px'}}>
      <div className="col-lg-8">
        <div className="row" style={{display:'flex', justifyContent:'center'}}>
          <div className="card" style={{width:'97.5%', paddingInline:'0px'}}>
            <div className="card-body" style={{paddingInline:'0px'}}>
              <h3 className='report-input-heading'>Monthly water usage report description (optional)</h3>

              <input  type="text" className="form-control dropdown-toggle" id="reportInput" 
              placeholder='type your text'data-bs-toggle="dropdown" data-bs-offset="0,0"
              onChange={(event) => setReport(event.target.value)}
              value={dropDownItem !== "" ? dropDownItem : report}
              style={{borderWidth:"0px 0px 1px 0px", borderRadius:'0px', width:'100%'}}/>
              
              {report !== "" ? 
              <img
                src={crossIcon} alt="icon"
                style={{ position: 'absolute', right: '7px', bottom: '25px', cursor:'pointer'}}
                onClick={() => {
                  setReport("");
                }}
              >
              </img> : ""}
              <ul className="dropdown-menu" aria-labelledby='reportInput'
              style={{width:"100%",borderTop:"0px", borderRadius:"0px"}}>
                {
                  dropDownItems.map((item, index) => (
                  <React.Fragment key={index}>
                    <li key={item}>
                      <p
                        className="dropdown-item"
                        onMouseEnter={() => setDropDownItem(item)}
                        onMouseLeave={() => setDropDownItem("")}
                        onClick={() => setReport(item)}
                      >
                        {item}
                      </p>
                    </li>
                    {index !== dropDownItems.length - 1 && <li key={`${item}-divider`}><hr className="dropdown-divider"/></li>}
                  </React.Fragment>
                  ))
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default InputForm