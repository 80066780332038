import React,{useState, useEffect, useMemo} from 'react'
import './chartComponent.css'
import AreaChart from './AreaChart'
import refreshIcon from '../../../Icons/refresh-icon.svg'
import axios from 'axios';
import { USER_SERVER } from '../../Config.js';

function ChartComponent() {
  const today = new Date();
  const [selectedYear, setSelectedYear] = useState(today.getFullYear()); // Initial selected year
  const [criteria, setCriteria] = useState("Cost");
  const [buttonCost, setButtonCost] = useState(true);
  const [lastKMonths, setLastKMonths] = useState(12);
  const [installationInfo, setInstallationInfo] = useState({month: 1, year: 2099});
  const [loadingReport, setLoadingReport] = useState(true);
  const [loadingDate, setLoadingDate] = useState(true);
  const [error, setError] = useState(null);
  const [yearlyCost, setYearlyCost] = useState(0);
  const [yearArr, setYearArr] = useState([today.getFullYear()]);
  const [data, setData] = useState({
    year: [],
    months: [],
    cost: [],
    volume: []
  });

  const [chartData, setChartData] = useState({
    cost: [],
    vol: [],
    months: [],
    criteria: 'Cost',
    year: selectedYear,
    installationIndex : 0,
    dataLength:12
  })

  const numberWithCommas = (number) => {
    return number.toLocaleString('en-US');
  };

  const getMonthDiff = (month1, year1, month2, year2) => {
    const m1 = parseInt(month1, 10);
    const y1 = parseInt(year1, 10);
    const m2 = parseInt(month2, 10);
    const y2 = parseInt(year2, 10);

    // Calculate the difference in months
    return (y1 * 12 + m1) - (y2 * 12 + m2);
  }

  const monthMapping = useMemo(
    () => ({
      1: "Jan", 2: "Feb", 3: "Mar", 4: "Apr", 5: "May", 6: "Jun",
      7: "Jul", 8: "Aug", 9: "Sep", 10: "Oct", 11: "Nov", 12: "Dec"
    }),
    [] // Empty dependency array ensures it is only created once
  );

  function getMonthYear(date){
    date = new Date(date);
    const month = date.getMonth()+1;
    const year = date.getFullYear();
    return [month, year];
  }

  useEffect(() =>{
    const orgID = '6710aef634e00f4f477a0086';
    axios.get(`${USER_SERVER}/getInstallationDate/${orgID}`, { withCredentials: true }).then(
      response => {
        if(response.data.success){
          const [month, year] = getMonthYear(response.data.installDate)
          setInstallationInfo({month: month, year: year});
        }else{
          console.log(response.data.message);
        }
        setLoadingDate(false);
      }
    ).catch(err => {
      console.error('Error fetching reports:', err);
      setLoadingDate(false);
      setError('Failed to fetch reports. Please refresh'); // Catch any errors
    });
  },[]);

  useEffect(() =>{
    const orgID = '6710aef634e00f4f477a0086';
    axios
      .get(`${USER_SERVER}/getMonthlyReports/${orgID}`, { withCredentials: true })
      .then(response => {
        if (response.data.success) {
          const sortedData = response.data.data.sort((a, b) =>
            new Date(a.yearMonth) - new Date(b.yearMonth) // Sort in ascending order
          );
          const costArray = sortedData.map(item => item.cost);
          const volumeArray = sortedData.map(item => item.volume);
          const monthArray = sortedData.map(item => item.yearMonth.split('-')[1]);
          const yearArray = sortedData.map(item => item.yearMonth.split('-')[0]);
          setSelectedYear(yearArray[yearArray.length-1]);
          setYearArr(Array.from(new Set(yearArray)).reverse());
          // console.log("sorted Data:", sortedData);
          setData(prevState =>({
            prevState,
            cost: costArray,
            volume: volumeArray,
            year: yearArray,
            months: monthArray
          }))
        } else {
          alert(response.data.message); // Handle any API errors
        }
        setLoadingReport(false); // Stop loading once the request completes
      })
      .catch(err => {
        console.error('Error fetching reports:', err);
        setLoadingReport(false);
        setError('Failed to fetch reports. Please refresh'); // Catch any errors
      });
  },[])

  useEffect(() => {
    const installationMonth = installationInfo.month; // water saving device installation month 9
    const installationYear = installationInfo.year; // water saving installation year 23

    let cost = [];
    let vol = [];
    let months = [];
    let years = [];
    let lastMonth = 0; // last month of the selected data on chart
    data.year.forEach((year, index) => {
        if (Number(year) <= Number(selectedYear)) {
            cost.push(data.cost[index]);
            vol.push(data.volume[index]);
            months.push(data.months[index]);
            years.push(data.year[index]);
        }
    });

    const totalLength = months.length;
    // Slice the values based on the last K months for which data needs to be shown on chart.
    const slicingIndex = totalLength >= lastKMonths ? lastKMonths : totalLength;
    cost = cost.slice(-slicingIndex); 
    vol = vol.slice(-slicingIndex);
    months = months.slice(-slicingIndex);
    years = years.slice(-slicingIndex);

    lastMonth = months.length > 0 ? months[months.length-1] : 0; // last month of the selected data on chart
    // convert the month to month'year format (eg. Jan -> Jan'23)
    months = months.map((month, index) => `${monthMapping[month]}'${years[index]%100}`);

    // month difference between last month on chart and the device installation month.
    const monthDiff = getMonthDiff(lastMonth, selectedYear, installationMonth, installationYear);

    // index at which chart will differ itc color based on the installation month.
    let installationIndex = monthDiff < 0 ? months.length - 1 : months.length - monthDiff -1;
    // if installation month is not in the range of data shown on chart set the index to 0
    installationIndex = installationIndex < 0 ? 0 : installationIndex;

    setChartData((prevChartData) =>({
      ...prevChartData,
      cost: cost,
      vol: vol,
      months: months,
      year: selectedYear,
      installationIndex: installationIndex,
      dataLength: months.length
    }));

    },[selectedYear, lastKMonths, monthMapping, data, installationInfo.month, installationInfo.year]);

    useEffect(() =>{
    setChartData((prevChartData) =>({
        ...prevChartData,
        criteria: criteria
    }));
    }, [criteria])

    useEffect(() =>{
      const { year, cost } = data;

    // Map to store total cost and month counts for each year
    const yearData = {};

    // Step 1: Group costs and months by year
    for (let i = 0; i < year.length; i++) {
        const currentYear = year[i];
        const currentCost = cost[i];

        if (!yearData[currentYear]) {
            yearData[currentYear] = { totalCost: 0, monthCount: 0 };
        }

        // Accumulate cost and month count for the current year
        yearData[currentYear].totalCost += currentCost;
        yearData[currentYear].monthCount += 1;
    }

    // Step 2: Calculate the weighted average
    let weightedCostSum = 0;
    let totalMonths = 0;

    for (const year in yearData) {
        const { totalCost, monthCount } = yearData[year];
        weightedCostSum += totalCost * monthCount;
        totalMonths += monthCount;
    }

    // Step 3: Return the weighted average yearly cost
     if(totalMonths > 0 ) setYearlyCost(weightedCostSum / totalMonths);
    },[data])
    
    if(loadingReport || loadingDate){
        return <p>Loading data...</p>;
    }

    if (error) return <p>Error: {error}</p>;

    if(data.year.length === 0) return <p>No Data Found</p>;
  return (
    <div>
        <div className="row" style={{display:'flex', justifyContent:'space-between'}}>
            <h5 className="card-title">
                Cost of water
            </h5>
            <img src = {refreshIcon} alt = "refreshIcon" 
                 style={{width:'32px', height:'32px', cursor:'pointer', padding:'0px', marginRight:'10px'}}
                 onClick={() => {setSelectedYear(today.getFullYear()); setLastKMonths(12)}}
            />
        </div>
        <span className='yearlyOuter'>
            Yearly: <span className='yearlyInner'>&#165; {numberWithCommas(Math.floor(yearlyCost))}</span>
        </span>

        {/* Month or Year selection buttons */}
        <div className="d-grid gap-2 d-sm-flex justify-content-sm-end">
            <div className="btn-group">
                <button type="button" className="btn btn-light btn-outline-dark" id = "monthChange"
                        style={{backgroundColor: '#fff', paddingInline: '20px', 
                        borderColor: '#D9D9D9', borderRadius: '5px 0px 0px 5px',
                        fontFamily:"Poppins", fontSize:"12px", fontWeight:500, color:"#313131"}}>
                    {`last ${lastKMonths} months`}
                </button>
                <button type="button" className="btn btn-light btn-outline-dark dropdown-toggle dropdown-toggle-split" id = "monthChangeDropdown"
                        data-bs-toggle="dropdown" aria-expanded="false"
                        style={{borderLeft:'none', backgroundColor: '#fff',borderColor: '#D9D9D9',  borderRadius: '0px 5px 5px 0px',}}>
                <span className="visually-hidden">Toggle Dropdown</span>
                </button>
                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby='monthChangeDropdown'>
                {[6, 12, 18, 24].map((months) => (
                    <li key={months}>
                    <button
                        className="dropdown-item"
                        id = {months}
                        onClick={() => {setLastKMonths(months)}}
                    >
                        {`last ${months} months`}
                    </button>
                    </li>
                ))}
                </ul>
            </div>
            <div className="btn-group">
                <button type="button" className="btn btn-light btn-outline-dark" id = "yearChange"
                        style={{backgroundColor: '#fff', paddingInline: '20px', 
                        borderColor: '#D9D9D9', borderRadius: '5px 0px 0px 5px',
                        fontFamily:"Poppins", fontSize:"12px", fontWeight:500, color:"#313131"}}>
                    Per Year
                </button>
                <button type="button" className="btn btn-light btn-outline-dark dropdown-toggle dropdown-toggle-split" 
                        id = "yearChangeDropdown" data-bs-toggle="dropdown" aria-expanded="false"
                        style={{backgroundColor: '#fff', borderRadius: '0px 5px 5px 0px', borderColor: '#D9D9D9', 
                        fontFamily:"Poppins", fontSize:"12px", fontWeight:500, color:"#313131"}}>
                <span style={{paddingRight: '0.5rem'}}>{selectedYear}</span>
                </button>
                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby='yearChangeDropdown'>
                {yearArr.map((year) => (
                    <li key={year}>
                    <button
                        className="dropdown-item"
                        id = {year}
                        onClick={() => setSelectedYear(year)}
                    >
                        {year}
                    </button>
                    </li>
                ))}
                </ul>
            </div>
        </div>

        <AreaChart chartData = {chartData}/>

        {/* Criteria Selection Buttons */}
        <div className="row" style={{marginInline: '40px'}}>
        <div className="d-grid col-6 mx-auto">
            <button className={buttonCost === true ? "btn btn-dark" : "btn btn-secondary"} type="button"
            style={{borderRadius:"0.2rem", fontFamily:"Poppins", fontSize:"12px", fontWeight:500}} id = "criteriaCost"
            onClick={() => {setCriteria("Cost"); setButtonCost(true)}}>Cost</button>
        </div>
        <div className="d-grid col-6 mx-auto">
            <button className={buttonCost === true ? "btn btn-secondary" : "btn btn-dark"} type="button"
            style={{borderRadius:"0.2rem", fontFamily:"Poppins", fontSize:"12px", fontWeight:500}} id = "criteriaVolume"
            onClick={() => {setCriteria("Cubic meter"); setButtonCost(false)}}
            >Cubic meters</button>
        </div>
        </div>
    </div>
  )
}

export default ChartComponent