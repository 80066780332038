import React, {useEffect, useState} from 'react'
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import './sideBar.css'
import homeScreenIconLight from '../../Icons/homescreen-icon-light.svg'
import homeScreenIconDark from '../../Icons/homescreen-icon-dark.svg'
import waterDropIconLight from '../../Icons/water-drop-half-light.svg'
import waterDropIconDark from '../../Icons/water-drop-half-dark.svg'
import editIconLight from '../../Icons/edit-icon-light.svg'
import editIconDark from '../../Icons/edit-icon-dark.svg'


function SideBar() {
  const [currentTab, setCurrentTab] = useState(1);
  const user = useSelector(state => state.user);  // Gets user state from Redux
//   console.log("User:", user);
  useEffect(() =>{
    const currentPath = window.location.pathname;
    currentPath === '/' ? setCurrentTab(1) : currentPath === '/dashboard' ? setCurrentTab(2) : setCurrentTab(3);
  },[])
  
  return (
    <aside id="sidebar" className="sidebar">
        <ul id = "sidebar-nav" className ="sidebar-nav">
            <li className='nav-item' style={{ position: 'relative'}}>
                {currentTab === 1? <span className="vertical-border" ></span>: <span></span>}
                <Link className='nav-link' to = "/" style={{backgroundColor:'#fff'}}
                onClick={() => {setCurrentTab(1)}}>
                    <img src={currentTab === 1 ? homeScreenIconLight : homeScreenIconDark} style={{marginRight: '10px'}} alt="Icon"></img>
                    <span className='itemText' style={{color: currentTab === 1? "#00A2B9" : "#A0AFC4"}}>Homescreen</span>
                </Link>
            </li>

            <li className='nav-item' style={{ position: 'relative' }}>
                {currentTab === 2? <span className="vertical-border" ></span>: <span></span>}
                <Link className='nav-link' to = "/dashboard"  style={{backgroundColor:'#fff'}}
                onClick={() => {setCurrentTab(2)}}>
                    <img src={currentTab === 2 ? waterDropIconLight : waterDropIconDark} style={{marginRight: '10px'}} alt="Icon"></img>
                    <span className='itemText' style={{color: currentTab === 2? "#00A2B9" : "#A0AFC4"}}>Water consumption</span>
                </Link>
            </li>

            { user.userData && user.userData.role === 1 ?
                <li className='nav-item' style={{ position: 'relative' }}>
                    {currentTab === 3? <span className="vertical-border" ></span>: <span></span>}
                    <Link className='nav-link' to = "/edit"  style={{backgroundColor:'#fff'}}
                    onClick={() => {setCurrentTab(3)}}>
                        <img src={currentTab === 3 ? editIconLight : editIconDark} style={{marginRight: '10px'}} alt="Icon"></img>
                        <span className='itemText' style={{color: currentTab === 3? "#00A2B9" : "#A0AFC4"}}>
                            Edit data
                        </span>
                    </Link>
                </li> : <span></span>
            }
        </ul>
    </aside>
  )
}

export default SideBar