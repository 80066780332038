import React, {useState, useEffect} from 'react'
import './homepage.css'
import DoughnutChart from './Sections/DoughnutChart'
import WaterUseCard from './Sections/WaterUseCard'
import axios from 'axios';
import { USER_SERVER } from '../Config.js';

function HomePage() {
  const [data, setData] = useState([]);
  const [loadingReport, setLoadingReport] = useState(true);
  const [loadingDate, setLoadingDate] = useState(true);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [actualDate, setActualDate] = useState(null);
  const [actualMonthCost, setActualMonthCost] = useState(0);
  const [actualMonthVol, setActualMonthVol] = useState(0);
  const [waterSaving, setWaterSaving] = useState(0);

  function getMonthYear(date){
      date = new Date(date);
      const month = date.getMonth()+1;
      const year = date.getFullYear();
      return `${year}-${month}`;
  }

  function formatYearMonth(yearMonth) {
    const [year, month] = yearMonth.split('-');
    const date = new Date(year, month - 1); // month is 0-indexed in JS Date
  
    const monthName = date.toLocaleString('default', { month: 'short' }); // e.g., 'October'
    return `${monthName}, ${year}`; // e.g., 'October, 2024'
  }

  useEffect(() =>{
    const orgID = '6710aef634e00f4f477a0086';
    axios.get(`${USER_SERVER}/getInstallationDate/${orgID}`, { withCredentials: true }).then(
      response => {
        if(response.data.success){
          setStartDate(getMonthYear(response.data.installDate))
        }else{
          console.log(response.data.message);
        }
        setLoadingDate(false);
      }
    ).catch(err => {
      console.error('Error fetching reports:', err);
      setLoadingDate(false);
      setError('Failed to fetch reports. Please refresh'); // Catch any errors
    });
  },[]);

  useEffect(() =>{
    const orgID = '6710aef634e00f4f477a0086';
    axios
      .get(`${USER_SERVER}/getMonthlyReports/${orgID}`, { withCredentials: true })
      .then(response => {
        if (response.data.success) {
          const sortedData = response.data.data.sort((a, b) =>
            new Date(a.yearMonth) - new Date(b.yearMonth) // Sort in ascending order
          );

          setData(sortedData);
          if(sortedData.length > 0)setActualDate(sortedData[sortedData.length-1].yearMonth)

        } else {
          alert(response.data.message); // Handle any API errors
        }
        setLoadingReport(false); // Stop loading once the request completes
      })
      .catch(err => {
        console.error('Error fetching reports:', err);
        setLoadingReport(false);
        setError('Failed to fetch reports. Please refresh'); // Catch any errors
      });
  },[]);

  useEffect(() =>{
    const acutalDateItem = data.find(item => item.yearMonth === actualDate);
    if(acutalDateItem) setActualMonthCost(acutalDateItem.cost);
    if(acutalDateItem) setActualMonthVol(acutalDateItem.volume);
  },[data, actualDate]);

  useEffect(() =>{
    let total_vol_before_installation = 0;
    let total_vol_after_installation = 0;
    let total_month_before_installation = 0;
    let total_month_after_installation = 0;

    for(const report of data){
      const reportDate = report.yearMonth;
      if(reportDate < startDate){
        total_vol_before_installation += report.volume;
        total_month_before_installation += 1;
      }else{
        total_vol_after_installation += report.volume;
        total_month_after_installation += 1;
      }
    }

    let average_before = total_month_before_installation !== 0 ? 
      (total_vol_before_installation/total_month_before_installation) : 0;

    let average_after = total_month_after_installation !== 0 ? 
      (total_vol_after_installation/total_month_after_installation) : 0;

    if(average_before !== 0){
       setWaterSaving(Math.floor((average_before - average_after)*100/average_before));
    }
  },[data, startDate])


  const numberWithCommas = (number) => {
    return number.toLocaleString('en-US');
  };

    if(loadingDate || loadingReport){ 
      return <div className="card">
          <div className="card-body">
              <p> Data Loading</p>
          </div>
      </div>;
  }
  if(error){ 
      return <div className="card">
          <div className="card-body">
              <p>Error Loading Data : {error}</p>
          </div>
      </div>;
  }
  if(data.length === 0){ 
      return <div className="card">
          <div className="card-body">
              <p> No Data Found</p>
          </div>
      </div>;
  }
  return (
    <main id='main' className="main">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <h3 className="cardTitle">
                Total Average Water Savings
              </h3>
              <h2 className="cardTitleDesc">
              Comparing the water use per month before and after installing DG TAKANO products, averaged for the entire recorded period
              </h2>
            </div>
            <div className="row">
              <div className="col-lg-7">
                <DoughnutChart value={waterSaving}/>
              </div>

              <div className="col-lg-5">
                <div className="row innerCard">
                  <WaterUseCard type = "volume" value = {numberWithCommas(actualMonthVol)} month = {formatYearMonth(actualDate)}/>
                </div>
                <div className="row innerCard">
                  <WaterUseCard type = "cost" value = {numberWithCommas(actualMonthCost)} month = {formatYearMonth(actualDate)}/>
                </div>
              </div>
            </div>
          </div>
        </div>
    </main>
  )
}

export default HomePage