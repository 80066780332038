import React, { useState } from "react";
import { useNavigate } from "react-router-dom";  // Import useNavigate
import { loginUser } from "../../../_actions/user_actions";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, Input, Button, Checkbox} from 'antd';
import { useDispatch } from "react-redux";
import './loginForm.css';

function LoginForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();  // Use useNavigate instead of withRouter
  const rememberMeChecked = localStorage.getItem("rememberMe") ? true : false;

  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [rememberMe, setRememberMe] = useState(rememberMeChecked);

  const handleRememberMe = () => {
    setRememberMe(!rememberMe);
  };

  const initialEmail = localStorage.getItem("rememberMe") || '';

  return (
    <Formik
      initialValues={{
        email: initialEmail,
        password: '',
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Email is invalid')
          .required('Email is required'),
        password: Yup.string()
          .min(6, 'Password must be at least 6 characters')
          .required('Password is required'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          let dataToSubmit = {
            email: values.email,
            password: values.password
          };

          dispatch(loginUser(dataToSubmit))
            .then(response => {
              console.log("login res: ");
              console.log(response);
              if (response.payload.loginSuccess) {
                window.localStorage.setItem('userId', response.payload.userId);
                if (rememberMe) {
                  window.localStorage.setItem('rememberMe', values.id);
                } else {
                  localStorage.removeItem('rememberMe');
                }
                navigate("/");  // Use navigate instead of props.history.push
              } else {
                setFormErrorMessage('Check your Account or Password again');
              }
            })
            .catch(err => {
              setFormErrorMessage('Check your Account or Password again');
              setTimeout(() => {
                setFormErrorMessage('');
              }, 3000);
            });

          setSubmitting(false);
        }, 500);
      }}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;

        return (
          <div className="card login">
            <h3 className='FormTitle'>Sign in to your account</h3>
            <form onSubmit={handleSubmit} style={{ width: '350px' }}>
              <Form.Item required>
                <Input
                  id="email"
                //   prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Enter your email"
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.email && touched.email ? 'text-input error' : 'text-input'
                  }
                />
                {errors.email && touched.email && (
                  <div className="input-feedback">{errors.email}</div>
                )}
              </Form.Item>

              <Form.Item required>
                <Input
                  id="password"
                //   prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="Enter your password"
                  type="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.password && touched.password ? 'text-input error' : 'text-input'
                  }
                />
                {errors.password && touched.password && (
                  <div className="input-feedback">{errors.password}</div>
                )}
              </Form.Item>

              {formErrorMessage && (
                <label>
                  <p style={{
                    color: '#ff0000bf',
                    fontSize: '0.7rem',
                    border: '1px solid',
                    padding: '1rem',
                    borderRadius: '10px'
                  }}>
                    {formErrorMessage}
                  </p>
                </label>
              )}

              <Form.Item>
                <Checkbox id="rememberMe" onChange={handleRememberMe} checked={rememberMe}>
                  Remember me
                </Checkbox>
                <a className="login-form-forgot" href="/reset_user" 
                style={{ float: 'right', marginBottom:'15px',
                 fontFamily:"Poppins", fontWeight:400, fontSize:'10px', color:'#00A2B9'}}>
                  Forgot password
                </a>
                <div>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    disabled={isSubmitting}
                    onSubmit={handleSubmit}
                    style={{
                      backgroundColor: '#00A2B9',
                      height: '50px',
                      fontSize: '20px',
                      borderRadius: '5px',
                      width: '100%'
                    }}
                  >
                    Continue
                  </Button>
                </div>
              </Form.Item>
            </form>
          </div>
        );
      }}
    </Formik>
  );
}

export default LoginForm;




// import React from 'react';
// import {Link} from 'react-router-dom';
// import './loginForm.css';

// function LoginForm() {
//   return (
//     <div className="card login" style={{position:'relative'}}>
//         <ul className="nav nav-tabs" id="myTab" role="tablist">
//             <li className="nav-item" role="presentation">
//                 <button className="nav-link active" id="user-tab" data-bs-toggle="tab" data-bs-target="#user" 
//                 type="button" role="tab" aria-controls="user" aria-selected="true" style={{borderRadius: '5px 0px 0px 0px'}}>
//                     Log in as <b>user</b>
//                 </button>
//             </li>
//             <li className="nav-item" role="presentation">
//                 <button className="nav-link login" id="admin-tab" data-bs-toggle="tab" data-bs-target="#admin" 
//                 type="button" role="tab" aria-controls="admin" aria-selected="false" style={{borderRadius: '0px 5px 0px 0px'}}>
//                     Log in as <b>admin</b>
//                 </button>
//             </li>
//         </ul>
//         <div className="tab-content" id="myTabContent">
//             <div className="tab-pane fade show active" id="user" role="tabpanel" aria-labelledby="user-tab">
//                 <form className="form row g-3" id = "user-form">
//                     <h3 className='FormTitle'>Sign in to your account</h3>
//                     <div className="col-12">
//                         <label htmlFor="inputEmailUser" className="form-label">Email</label>
//                         <input type="text" className="form-control" id="inputEmailUser" placeholder="example@gmail.com"/>
//                     </div>
//                     <div className="col-12">
//                         <label htmlFor="inputPasswordUser" className="form-label"
//                         style={{display:'flex', justifyContent:'space-between', width:'100%'}}>
//                             <span>Password</span>
//                             <Link style={{fontFamily:"Poppins", fontWeight:400, fontSize:'10px', color:'#00A2B9'}}>
//                                 forgot password
//                             </Link>
//                         </label>
//                         <input type="text" className="form-control" id="inputPasswordUser" placeholder=""/>
//                     </div>
//                     <div className="col-12">
//                         <div className="form-check">
//                         <input className="form-check-input" type="checkbox" id="gridCheck"/>
//                         <label className="form-check-label" htmlFor="gridCheck">
//                             Stay signed in
//                         </label>
//                         </div>
//                     </div>
//                     <div className="d-grid gap-2">
//                         <button type="submit" className="btn btn-primary" 
//                             style={{backgroundColor:'#00A2B9', height:'50px', fontSize:'20px', borderRadius:'5px'}}>
//                                 Continue
//                         </button>
//                         <Link to = "/"
//                               style={{display:'flex', justifyContent:'center', marginTop:'10px',
//                               fontFamily: "Poppins", fontWeight: 400, fontSize:"12px", color: "#00A2B9"}}>
//                             Use single sign-on (SSO) instead
//                         </Link>
//                     </div>
//                 </form>
//             </div>
//             <div className="tab-pane fade" id="admin" role="tabpanel" aria-labelledby="admin-tab">
//                 <form className="form row g-3" id = "admin-form">
//                     <h3 className='FormTitle'>Sign in to your account</h3>    
//                     <div className="col-12">
//                         <label htmlFor="inputEmailAdmin" className="form-label">Email</label>
//                         <input type="text" className="form-control" id="inputEmailAdmin" placeholder="example@gmail2.com"/>
//                     </div>
//                     <div className="col-12">
//                         <label htmlFor="inputPasswordAdmin" className="form-label"
//                         style={{display:'flex', justifyContent:'space-between', width:'100%'}}>
//                             <span>Password</span>
//                             <Link style={{fontFamily:"Poppins", fontWeight:400, fontSize:'10px', color:'#00A2B9'}}>
//                                 forgot password
//                             </Link>
//                         </label>
//                         <input type="text" className="form-control" id="inputPasswordAdmin" placeholder=""/>
//                     </div>
//                     <div className="col-12">
//                         <div className="form-check">
//                         <input className="form-check-input" type="checkbox" id="gridCheck"/>
//                         <label className="form-check-label" htmlFor="gridCheck">
//                             Stay signed in
//                         </label>
//                         </div>
//                     </div>
//                     <div className="d-grid gap-2">
//                         <button type="submit" className="btn btn-primary" 
//                         style={{backgroundColor:'#00A2B9', height:'50px',fontFamily:"Poppins", fontSize:'16px', borderRadius:'5px'}}>
//                             Continue
//                         </button>
//                         <Link to = "/"
//                               style={{display:'flex', justifyContent:'center', marginTop:'10px',
//                               fontFamily: "Poppins", fontWeight: 400, fontSize:"12px", color: "#00A2B9"}}>
//                             Use single sign-on (SSO) instead
//                         </Link>
//                     </div>
//                 </form>
//             </div>
//         </div>
//     </div>
//   )
// }

// export default LoginForm