import React from 'react'
import './dashBoard.css'
import LeftSection from './Sections/LeftSection'
// import RightSection from './Sections/RightSection'

function DashBoard() {
  function getDate() {
    const today = new Date();
    const dayName = today.toLocaleString('default', {weekday: 'long'});
    const monthName = today.toLocaleString('default', { month: 'short' });
    const year = today.getFullYear();
    const date = today.getDate();
    return `${dayName}, ${date} ${monthName}, ${year}`;
  }
  return (
    <main id = "main" className='main'>
      <div className='pagetitle'>
        <h1 className="dashBoardTitle">Water consumption</h1>
        <h6 className='dateText'>{getDate()}</h6>
      </div>
      <section className="dashboard section">
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
                <div className="col-12">
                  <LeftSection/>
                </div>
            </div>
          </div>
          {/* <div className="col-lg-4">
            <div className="row">
                <div className="col-12">
                  <RightSection/>
                </div>
            </div>
          </div> */}
        </div>
      </section>
    </main>
  )
}

export default DashBoard