import React from 'react'
import './leftSection.css'

import ChartComponent from './ChartComponent'
import TableComponent from './TableComponent'

function LeftSection() {

  return (
    <div>
      {/* Chart Component */}
      <div className="card upper">
        <div className="card-body chart">
            <ChartComponent/>
        </div>
      </div>

      {/* Table Component */}
      <div className="card bottom">
        <div className="card-body table">
          <TableComponent/>
        </div>
      </div>
    </div>
  )
}

export default LeftSection