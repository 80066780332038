import React, {useEffect} from 'react'
import * as echarts from 'echarts'


function AreaChart(props) {
    useEffect(() => {
        const legend = props.chartData.criteria === 'Cost' ? "Cost of water" : "Cubic meter of water";
        const yAxisTitle = props.chartData.criteria === "Cost" ? " Cost (¥)" : "Cubic meter (m³)"
        const existingChart = echarts.getInstanceByDom(document.getElementById('areaChart'));

        if (existingChart) {
            existingChart.dispose();
        }
        echarts.init(document.querySelector('#areaChart')).setOption({
            color: ['#80FFA5'],
            // legend:{
            //     data: [legend],
            //     left: 'left',
            //     top: '3%',
            // },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'line',
                  label: {
                    backgroundColor: '#6a7985',
                  },
                },
                formatter: function (params) {
                  let tooltipContent = `${params[0].axisValueLabel}<br/>`;
          
                  params.forEach((item) => {
                      const value = item.seriesName === "Cost of water" ? `${item.value.toLocaleString('en-US')} ¥` : `${item.value.toLocaleString('en-US')} m³`; // Add ¥ for cost
                      tooltipContent += `${item.marker} ${item.seriesName}: ${value}<br/>`; // Show series name and value
                  });
          
                  return tooltipContent;
              }
            },
            graphic: {
              elements: props.chartData.dataLength === 0 ?[ // Define multiple text elements
                  {
                      type: 'text',
                      left: 'center',
                      top: 'center',
                      z: 12,
                      cursor:'text',
                      style: {
                      text: 'No data available for selected year', // Text content
                      textAlign: 'center',
                      fill: '#00A2B9',
                      fontFamily: "Poppins",
                      fontWeight: 700,
                      fontSize: '24px'
                      }
                  }
              ] : []
            },

            xAxis: [
                {
                  type: 'category',
                  boundaryGap: false,
                  data: props.chartData.months,
                  name: 'Months',
                  nameTextStyle:{
                    font: "Poppins",
                    fontWeight: 600,
                    fontSize: '12px',
                    color: '#000000'
                  },
                  nameLocation: 'middle',
                  nameGap: 40
                }
            ],
            yAxis: [
                {
                  type: 'value',
                  name: yAxisTitle,
                  nameTextStyle:{
                    font: "Poppins",
                    fontWeight: 600,
                    fontSize: '12px',
                    color: '#000000'
                  },
                  nameLocation: 'middle',
                  nameGap: 50
                }
            ],
            visualMap: {
              type: 'piecewise',
              show: true,
              top: "3%",
              right : "right",
              orient:"horizontal",
              dimension: 0,
              seriesIndex: 0,
              pieces: [
                {
                  gt: 0,
                  lt: props.chartData.installationIndex,
                  color: 'rgba(100, 250, 180, 0.8)',
                  label:"Before installation"
                },
                {
                  gt: props.chartData.installationIndex,
                  lt: props.chartData.dataLength,
                  color: 'rgba(0, 0, 180, 0.4)',
                  label: "After installation"
                }
              ]
            },

            series: [
                {
                  name: legend,
                  type: 'line',
                  stack: 'Total',
                  smooth: true,
                  lineStyle: {
                    width: 1,
                    color: 'black'
                  },
                  showSymbol: true,
                  symbolSize:10,
                  label: {
                    show: true,
                    position: 'top',
                    formatter: (params) => {
                      return props.chartData.criteria === 'Cost' ? `${params.value.toLocaleString('en-US')} ¥` : 
                                                                    `${params.value.toLocaleString('en-US')} m³`;
                    },
                  },
                  areaStyle: {
                    opacity: 0.8,
                  },
                  emphasis: {
                    focus: 'series'
                  },
                  data: props.chartData.criteria === "Cost"? props.chartData.cost : props.chartData.vol
                },
            ],
        })
    }, [props]);

  return (
    <div
        id='areaChart'
        style={{minHeight: '420px', width:'100%'}}
        className='echart'>
    </div>
  )
}

export default AreaChart