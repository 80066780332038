import React, {useState, useMemo, useEffect} from 'react'
import axios from 'axios';
import { USER_SERVER } from '../../Config.js';
import './tableComponent.css'
function TableComponent() {
  const [loadingReport, setLoadingReport] = useState(true); // To manage loading state
  const [loadingDate, setLoadingDate] = useState(true); // To manage loading state
  const [error, setError] = useState(null); // To handle any errors
  const [perMonthActive, setPerMonthActive] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [monthlyData, setMontlyData] = useState([]);
  const [yearlyData, setYearlyData] = useState([]);
  const [data, setData] = useState(monthlyData);
  const [InstallationDate, setInstallationDate] = useState("");
  

  const recordsPerPage = 15;
  const records = useMemo(
    () => data.slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage),
    [data, currentPage]
  );
  const nPages = Math.ceil(data.length/recordsPerPage);
  const pages = [...Array(nPages+1).keys()].slice(1);

  function getMonthYear(date){
    date = new Date(date);
    const month = date.getMonth()+1;
    const year = date.getFullYear();
    return new Date(year, month);
  }

  useEffect(() =>{
    const orgID = '6710aef634e00f4f477a0086';
    axios.get(`${USER_SERVER}/getInstallationDate/${orgID}`, { withCredentials: true }).then(
      response => {
        if(response.data.success){
          setInstallationDate(getMonthYear(response.data.installDate));
        }else{
          console.log(response.data.message);
        }
        setLoadingDate(false);
      }
    ).catch(err => {
      console.error('Error fetching reports:', err);
      setLoadingDate(false);
      setError('Failed to fetch reports. Please refresh'); // Catch any errors
    });
  },[]);

  useEffect(() => {
    const orgID = '6710aef634e00f4f477a0086';
    axios
      .get(`${USER_SERVER}/getMonthlyReports/${orgID}`, { withCredentials: true })
      .then(response => {
        if (response.data.success) {
          const sortedData = response.data.data.sort((a, b) =>
            new Date(a.yearMonth) - new Date(b.yearMonth) // Sort in ascending order
          );
          setMontlyData(sortedData);
          setData(sortedData);
        } else {
          setError(response.data.message); // Handle any API errors
        }
        setLoadingReport(false); // Stop loading once the request completes
      })
      .catch(err => {
        console.error('Error fetching reports:', err);
        setError('Failed to fetch reports. Please try again.'); // Catch any errors
        setLoadingReport(false); // Stop loading if the request fails
      });
  }, []); // Empty dependency array ensures the effect runs only once

  useEffect(() => {
    let yearlyData_ = [];
    let currYear = ""; 
    let currCost = 0;
    let currVol = 0;

    for (const report of monthlyData) {
        const Year = report.yearMonth.split('-')[0]; 

        if (Year === currYear) {
            currCost += report.cost;
            currVol += report.volume;
        } else {
            if (currYear !== "") {
                yearlyData_.push({
                    year: currYear,
                    cost: currCost,
                    volume: currVol,
                    report: "None"
                });
            }
            currYear = Year;
            currCost = report.cost;
            currVol = report.volume;
        }
    }

    if (currYear !== "") {
        yearlyData_.push({
            year: currYear,
            cost: currCost,
            volume: currVol,
            report: "None"
        });
    }

    setYearlyData(yearlyData_);
}, [monthlyData]);


  const numberWithCommas = (number) => {
    return number.toLocaleString('en-US');
  };

  function formatYearMonth(yearMonth) {
    const [year, month] = yearMonth.split('-');
    const date = new Date(year, month - 1); // month is 0-indexed in JS Date
  
    const monthName = date.toLocaleString('default', { month: 'long' }); // e.g., 'October'
    return `${monthName}, ${year}`; // e.g., 'October, 2024'
  }

  if (loadingReport || loadingDate) return <p>Loading reports...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
    <div className="d-grid gap-2 d-sm-flex justify-content-sm-end">
        <div className="btn-group">
            <button type="button" className={perMonthActive === true ? "btn btn-dark" : "btn btn-secondary"} 
                    onClick={() => {setPerMonthActive(true); setData(monthlyData)}} id = "perMonth"
                    style={{paddingInline: '20px', borderRadius: '0.2rem 0rem 0rem 0.2rem',
                    fontFamily:"Poppins", fontSize:"12px", fontWeight:500}}>
                Per Month
            </button>
            <button type="button" className={perMonthActive === false ? "btn btn-dark" : "btn btn-secondary"}
                    onClick={() => {setPerMonthActive(false); setData(yearlyData)}} id = "perYear"
                    style={{paddingInline: '25px', borderRadius: '0rem 0.2rem 0.2rem 0rem',
                    fontFamily:"Poppins", fontSize:"12px", fontWeight:500}}>
                Per Year
            </button>
        </div>
    </div>
    <table className="table table-borderless datatable" id='example'
            style={{marginTop: '10px'}}>
        <thead className="table-light">
            <tr style={{borderBottom:'none'}}>
                <th className='column-head' scope='col'>{perMonthActive === true ? 'Months' : 'Year'}</th>
                <th className='column-head' scope='col'>{perMonthActive === true ? 'Monthly Water Uses Report' : 'Annual Water Uses Report'}</th>
                <th className='column-head' scope='col'>Water Used</th>
                <th className='column-head' scope='col'>Water Used</th>
            </tr>
            <tr>
            <th className='column-head-fade' scope='col'></th>
            <th className='column-head-fade' scope='col'> </th>
            <th className='column-head-fade' scope='col'>(cubic meter)</th>
            <th className='column-head-fade' scope='col'>(cost, yen)</th>
        </tr>
        </thead>
        <tbody>
            { records.length > 0 ?
                (records.map(item =>(
                    <tr key = {item.yearMonth}>
                        <td className='column-1'
                            style={perMonthActive? 
                                   new Date(item.yearMonth.split('-')[0], item.yearMonth.split('-')[1]) < InstallationDate ? 
                                   {color:'rgba(20, 250, 150, 10)'}:
                                   {color: 'rgba(0, 0, 180, 0.4)'} :{}}
                        >
                            <b>{perMonthActive === true ? formatYearMonth(item.yearMonth) : item.year}</b>
                        </td>
                        <td className='column-2'
                            style={perMonthActive? 
                                new Date(item.yearMonth.split('-')[0], item.yearMonth.split('-')[1]) < InstallationDate ? 
                                {color:'rgba(20, 250, 150, 10)'}:
                                {color: 'rgba(0, 0, 180, 0.4)'} :{}}
                        >
                            <b>{item.report}</b>
                        </td>
                        <td className='column-3'
                            style={perMonthActive? 
                                new Date(item.yearMonth.split('-')[0], item.yearMonth.split('-')[1]) < InstallationDate ? 
                                {color:'rgba(20, 250, 150, 10)'}:
                                {color: 'rgba(0, 0, 180, 0.4)'} :{}}  
                        >
                            <b>{numberWithCommas(item.volume)} m3</b>
                        </td>
                        <td className='column-4'
                            style={perMonthActive? 
                                new Date(item.yearMonth.split('-')[0], item.yearMonth.split('-')[1]) < InstallationDate ? 
                                {color:'rgba(20, 250, 150, 10)'}:
                                {color: 'rgba(0, 0, 180, 0.4)'} :{}}
                        >
                            <b>&#165;{numberWithCommas(item.cost)}</b>
                        </td>
                    </tr>
                ))) : (
                    <tr>
                        <td colSpan="6" style={{ textAlign: 'center' }}>No reports found.</td>
                    </tr>
                )
            }
        </tbody>
    </table>
    <nav aria-label="..." className='table-pagination'>
        <ul className="pagination">
            <li className={currentPage === 1 ? "page-item disabled" : "page-item"}> 
                <button className="page-link"  aria-disabled="true"
                style={{border:"none"}}
                onClick={() => setCurrentPage(currentPage-1)}>
                    {'< Previous'}
                </button>
            </li>
            {
                pages.map((n, index) => (
                    <li className={n === currentPage? "page-item active" : "page-item"} aria-current="page" key = {index}>
                        <button className="page-link" onClick={() => setCurrentPage(n)} key={index}
                        style={currentPage === n ? {backgroundColor:'#fff',borderWidth:'1px', borderRadius:'5px', color:'#2D2D2D'}:{border:"none"}}>
                            {n}
                        </button>
                    </li>
                ))
            }
            <li className={currentPage === nPages ? "page-item disabled" : "page-item"}>
                <button className="page-link" aria-disabled="true"
                style={{border:"none"}}
                onClick={() => setCurrentPage(currentPage+1)}>
                    {'Next >'}
                </button>
            </li>
        </ul>
        </nav>
    </div>
  )
}

export default TableComponent
