import React, {useState, useEffect, useMemo} from 'react'
import './editableTable.css'
import editIcon from '../../../Icons/edit-icon.svg'
import deleteIcon from '../../../Icons/delete-icon.svg'
import axios from 'axios';
import { USER_SERVER } from '../../Config.js';

// Change the table tect colors
function EditableTable(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // To manage loading state
  const [error, setError] = useState(null); // To handle any errors
  const [currentPage, setCurrentPage] = useState(1);
  const [editedValues, setEditedValues] = useState({});
  const recordsPerPage = 5;
  const [editableRow, setEditableRow] = useState(null);

  useEffect(() => {
    const orgID = '6710aef634e00f4f477a0086';
    axios
      .get(`${USER_SERVER}/getMonthlyReports/${orgID}`, { withCredentials: true })
      .then(response => {
        if (response.data.success) {
          const sortedData = response.data.data.sort((a, b) =>
            new Date(a.yearMonth) - new Date(b.yearMonth) // Sort in ascending order
          );
          setData(sortedData); // Set the reports in state
        } else {
          setError(response.data.message); // Handle any API errors
        }
        setLoading(false); // Stop loading once the request completes
      })
      .catch(err => {
        console.error('Error fetching reports:', err);
        setError('Failed to fetch reports. Please try again.'); // Catch any errors
        setLoading(false); // Stop loading if the request fails
      });
  }, [props.change]); // Empty dependency array ensures the effect runs only once

//   const records = data.slice(firstIndex, lastIndex);
  const records = useMemo(
    () => data.slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage),
    [data, currentPage]
  );
  const nPages = Math.ceil(data.length/recordsPerPage);
  const pages = [...Array(nPages+1).keys()].slice(1);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setEditedValues((prev) => ({ ...prev, [id]: value }));
  };

  const handleEdit = (key) => {
    setEditableRow(key);
    const item = data.find((d) => d.yearMonth === key);
    setEditedValues(item); // Pre-fill the input values with existing data
  };


  const handleSave = (key) => {
    console.log("Edited Values: ", editedValues)
    axios
      .put(`${USER_SERVER}/updateMonthlyReport`, editedValues, { withCredentials: true })
      .then(response => {
        if (response.data.success) {
          // Update the local state with new data
          setData((prevData) =>
            prevData.map((item) =>
              item.yearMonth === key ? { ...item, ...editedValues } : item
            )
          );
          setEditableRow(null); // Close edit mode
        } else {
          alert('Failed to update the report.');
        }
      })
      .catch((err) => console.error('Error updating report:', err));
  };



  const handleDelete = (key) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this report?");
      if(isConfirmed){
        axios
        .delete(`${USER_SERVER}/deleteMonthlyReport`, { 
          data: { yearMonth: key }, // Sending yearMonth as part of the request body
          withCredentials: true 
        })
        .then(response => {
          if (response.data.success) {
            // Filter out the deleted item from the local state
            setData((prevData) => prevData.filter((item) => item.yearMonth !== key));
            console.log(`Deleted report for ${key}`);
          } else {
            alert('Failed to delete the report.');
          }
        })
        .catch((err) => console.log('Error deleting report:', err));
      }
  };
  

  const numberWithCommas = (number) => {
    return number.toLocaleString('en-US');
  };

  function formatYearMonth(yearMonth) {
    const [year, month] = yearMonth.split('-');
    const date = new Date(year, month - 1); // month is 0-indexed in JS Date
  
    const monthName = date.toLocaleString('default', { month: 'long' }); // e.g., 'October'
    return `${monthName}, ${year}`; // e.g., 'October, 2024'
  }

  if (loading) return <p>Loading reports...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
        <table className="table table-borderless datatable" id="example" style={{ marginTop: '10px' }}>
            <thead className="table-light">
                <tr style={{borderBottom:'none'}}>
                    <th className="column-head" scope="col"></th>
                    <th className="column-head" scope="col">Month</th>
                    <th className="column-head" scope="col">Monthly Water Uses Report</th>
                    <th className="column-head" scope="col">Water Used</th>
                    <th className="column-head" scope="col">Water Used</th>
                    <th className="column-head" scope="col">Edit</th>
                </tr>
                <tr>
                    <th className="column-head-fade" scope="col"></th>
                    <th className="column-head-fade" scope="col"></th>
                    <th className="column-head-fade" scope="col"></th>
                    <th className="column-head-fade" scope="col">(cubic meter)</th>
                    <th className="column-head-fade" scope="col">(cost, yen)</th>
                    <th className="column-head-fade" scope="col"></th>
                </tr>
            </thead>
            <tbody>
                {records.length > 0 ? (records.map((item) => (
                    <tr key={item.yearMonth}>
                        <td className="column-0">
                            <input type="checkbox" id={`$checkbox${item.yearMonth}`}/>
                        </td>
                        <td className="column-1">
                            {formatYearMonth(item.yearMonth)}
                        </td>
                        <td className="column-2">
                            {editableRow === item.yearMonth? (
                                <input className='inputTable' type="text" style={{width:'100%'}} id='report'
                                value={editedValues.report || ''}
                                onChange={handleChange}/>
                            ) : (
                                item.report
                            )}
                        </td>
                        <td className="column-3">
                            {editableRow === item.yearMonth ? (
                                <input className='inputTable' type="number"  id='volume'
                                value={editedValues.volume || ''}
                                onChange={handleChange}/>
                            ) : (
                                `${item.volume} m3`
                            )}
                            {/* <span className="badge rounded-pill" style={{ marginLeft: '10px', paddingInline: '10px' }}>
                                {item.percent}%
                            </span> */}
                        </td>
                        <td className="column-4">&#165;
                            {editableRow === item.yearMonth? (
                                <input className='inputTable' type="number" id='cost'
                                value={editedValues.cost || ''}
                                onChange={handleChange}/>
                            ) : (
                                `${numberWithCommas(item.cost)}`
                            )}
                        </td>
                        <td className="column-5">
                            {editableRow === item.yearMonth ? (
                                <button className='saveButton' onClick={() => handleSave(item.yearMonth)} id='saveButton'>Save</button>
                            ) : (
                                <div>
                                    <img className='editIcon' src={editIcon} alt="icon" onClick={() => handleEdit(item.yearMonth)}></img>
                                    <img className='deleteIcon' src={deleteIcon} alt="icon" onClick={() => handleDelete(item.yearMonth)}></img>
                                </div>
                            )}
                        </td>
                    </tr>
                ))) : (
                    <tr>
                        <td colSpan="6" style={{ textAlign: 'center' }}>No reports found.</td>
                    </tr>
                )}
            </tbody>
        </table>
        <nav aria-label="..." className='table-pagination'>
            <ul className="pagination">
                <li className={currentPage === 1 ? "page-item disabled" : "page-item"}> 
                    <button className="page-link"  aria-disabled="true"
                    style={{border:"none"}}
                    onClick={() => setCurrentPage(currentPage-1)}>
                        {'< Previous'}
                    </button>
                </li>
                {
                    pages.map((n, index) => (
                        <li className={n === currentPage? "page-item active" : "page-item"} aria-current="page" key = {index}>
                            <button className="page-link" onClick={() => setCurrentPage(n)}
                            style={currentPage === n ? {backgroundColor:'#fff',borderWidth:'1px', borderRadius:'5px', color:'#2D2D2D'}:{border:"none"}}>
                                {n}
                            </button>
                        </li>
                    ))
                }
                <li className={currentPage === nPages ? "page-item disabled" : "page-item"}>
                    <button className="page-link" aria-disabled="true"
                    style={{border:"none"}}
                    onClick={() => setCurrentPage(currentPage+1)}>
                        {'Next >'}
                    </button>
                </li>
            </ul>
        </nav>
    </div>
  )
}

export default EditableTable
