import React, { useEffect } from 'react';
import { auth } from '../_actions/user_actions';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom'; // Import useNavigate

export default function withAuthentication(ComposedClass, reload, adminRoute = null) {
    function AuthenticationCheck(props) {
        const user = useSelector(state => state.user);  // Gets user state from Redux
        const dispatch = useDispatch();
        const navigate = useNavigate();  // React Router v6 navigation

        useEffect(() => {

            dispatch(auth()).then(response => {
                // If user is not authenticated, redirect to login
                if (!response.payload.isAuth) {
                    if (reload) {
                        navigate('/login');  // Navigate to login
                    }
                } else {
                    // If route requires admin access and user is not admin
                    if (adminRoute && !response.payload.isAdmin) {
                        navigate('/');  // Redirect to home
                    } else if (reload === false) {
                        navigate('/');  // Redirect to home for already logged-in users
                    }
                }
            });
        }, [dispatch, navigate, user.googleAuth]);

        return <ComposedClass {...props} user={user} />;
    }

    return (props) => <AuthenticationCheck {...props} />;
}

