import React, {useState, useEffect} from 'react'
import './editData.css'
import InputForm from './Sections/InputForm'
import EditableTable from './Sections/EditableTable'
import Calendar from './Sections/Calendar';
import axios from 'axios';
import { USER_SERVER } from '../Config.js';
/*
Ensure these
  when user try to make duplicate entry for a month show a prompt to update data using edit table
  how to detect duplicate ? if(addMonth == existingMonth && addYear == existingYear)

  think what happens if user fill the data in random order. Do we need to sort the data before sending to database?
*/
function EditData() {
  const [installationDate, setInstallationDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [change, setChange] = useState(0);

  useEffect(() =>{
    const orgID = '6710aef634e00f4f477a0086';
    axios.get(`${USER_SERVER}/getInstallationDate/${orgID}`, { withCredentials: true }).then(
      response => {
        if(response.data.success){
          setInstallationDate(formatDate(response.data.installDate));
        }else{
          console.log(response.data.message);
        }
        setLoading(false);
      }
    ).catch(err => {
      setLoading(false);
      console.error('Error fetching reports:', err);
    });
  },[]);

  function handleSave (){
    axios.post(`${USER_SERVER}/editInstallationDate`, {installDate: selectedDate}, { withCredentials: true }).then(
      response => {
        if(response.data.success){
          setInstallationDate(formatDate(selectedDate));
          setSelectedDate(null);
          alert("Installation date edited successfully");
        }else{
          alert(response.data.message);
        }
      }
    ).catch(err => {
      console.error('Error fetching reports:', err);
    });
  }

  function formatDate(date){
    date = new Date(date);
    const dayName = date.toLocaleString('default', {weekday: 'short'});
    const monthName = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    const day = date.getDate();
    return `${dayName}, ${day} ${monthName}, ${year}`;
  }

  return (
    <main className="main" id='main'>
      <div className='headerRow'>
        <div className="row" style={{margin:'2px'}}>
          <div className="col-lg-6">
              <h1 className="pageTitle">Create new data entry</h1>
          </div>
          <div className="col-lg-2" style={{ textAlign: 'center' }}>
            <div className="card"
              style={{
                paddingInline: '5px',
                display: 'flex',
                flexDirection: 'column', // Change to column for vertical alignment
                justifyContent: 'center', // Center content vertically
                alignItems: 'center', // Center content horizontally
                height: '100%', // Ensure the card has a height for vertical centering
              }}
            >
                {
                  loading === true ? 
                  <p>Loading Installation Date</p> : 
                  installationDate == null ? 
                  <p>Please set the Installation Date</p> :
                  <div className="col">
                    <p>Installation Date</p>
                    <h4>{installationDate}</h4>
                  </div>
                }
            </div>
          </div>
          <div className="col-lg-4" style={{ textAlign: 'center'}}>
            <div className="card" 
                style={{
                  paddingInline: '5px',
                  display: 'flex',
                  flexDirection: 'column', // Change to column for vertical alignment
                  justifyContent: 'center', // Center content vertically
                  alignItems: 'center', // Center content horizontally
                  height: '100%', // Ensure the card has a height for vertical centering
                }}
            >
                <div className="col-lg-12" style = {{marginBlock:'5px' }}>
                  <h4>Edit Installation Date</h4>
                  <div className="row">
                    <div className="col-lg-8">
                      <Calendar selectedDate = {selectedDate} setSelectedDate = {setSelectedDate}/> 
                    </div>
                    <div className="col-lg-4 d-flex justify-content-center align-items-center">
                      <button className={selectedDate ? 'button-save active' : 'button-save'}
                      onClick={handleSave} disabled = {selectedDate == null}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
            </div> 
          </div>
        </div>
      </div>

      <div className="card" style={{margin: '10px'}}>
        <div className="card-body">
          <InputForm change = {change} setChange = {setChange}/>  
        </div>
      </div>

      <div className="card" style={{margin: '10px'}}>
        <div className="card-body">
          <h3 className="table-cardTitle">Entries</h3>
          <EditableTable change = {change} setChange = {setChange}/>
        </div>
      </div>
    </main>
  )
}

export default EditData