import React, {useEffect} from 'react'
import * as echarts from 'echarts'
import './doughnutChart.css'
function DoughnutChart(props) {
  useEffect(() => {
    const existingChart = echarts.getInstanceByDom(document.getElementById('doughnutChart'));
    if (existingChart) {
        existingChart.dispose();
    }
    const chart = echarts.init(document.querySelector('#doughnutChart'));
    const chartWidth = chart.getWidth(); // width of the chart-container
    const chartHeight = chart.getHeight(); // height of the chart-container
    const chartMeanRadius = 240; // (210+270)/2
    const angleOfRotation = 1.8*props.value*Math.PI/180; // 360 * (value/100) * (1/2) degree

    chart.setOption({
        backgroundColor: '#fff',
        graphic: {
          elements: [ // Define multiple text elements
              {
                  type: 'text',
                  top: '42%', // at 42% height from top
                  left: 'center', // centered in horizontal direction
                  z: 5,
                  cursor: 'text',
                  style: {
                  text: `${props.value}%`, // Text content
                  textAlign: 'center',
                  fill: '#000000', // text color: black
                  fontFamily:"Poppins",
                  fontWeight:800,
                  fontSize: '100px',
                  }
              },
              {
                  type: 'text',
                  top: '60%', // at 60% height from top
                  left: 'center', // centered in horizontal direction
                  z: 5,
                  cursor: 'text',
                  style: {
                  text: 'water Saving', // Text content
                  textAlign: 'center',
                  fill: '#000000', // text color : black
                  fontFamily:"Poppins",
                  fontWeight:500,
                  fontSize: '34px',
                  }
              },
              {
                  type: 'text',
                  top: `${chartHeight/2 - Math.cos(angleOfRotation)*chartMeanRadius - 15}px`,
                  left: `${chartWidth/2 + Math.sin(angleOfRotation)*chartMeanRadius - 30}px`,
                  // top: position of text with respect to the top edge of chart container,
                  // left: position of text with respect to the left edge of chart container,
                  z: 5,
                  cursor: 'text',
                  rotation: `${-angleOfRotation}`, // roation of text
                  padding:"0px",
                  style: {
                  text: `${props.value}%`, // Text content
                  textAlign: 'center',
                  fill: '#F9F9F9', // text color : white
                  fontFamily:"Poppins",
                  fontWeight:600,
                  fontSize: '30px',
                  }
              },
              ]
        },
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: ['210px', '270px'], // radius of inner and outer cirlces
        
        labelLine: {
          show: false, // connecting label line for labels shown outside the chart
        },
        data: [
          { value: props.value, name: '', },
          { value: 100-props.value, name: '' },
        ],
        color: ['#00A0B7', '#fff'],
        itemStyle: {
          shadowBlur: 5, // Shadow blur radius
          shadowColor: 'rgba(136, 136, 136, 0.25)', // Shadow color
          shadowOffsetX: 0, // Shadow offset X
          shadowOffsetY: 0, // Shadow offset Y
      },
      }
    ] 
    })
}, [props]);

  return (
    <div
        id='doughnutChart'
        style={{minHeight:'600px', marginBlock:'10px'}}
        className='echart'
    >
    </div>
  )
}

export default DoughnutChart