import React,{useState, forwardRef} from 'react'
import './calendar.css'
import calendarIcon from '../../../Icons/calendar-icon.svg'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'

function Calendar({selectedDate, setSelectedDate}) {
  // const [selectedDate, setSelectedDate] = useState(null);
  const [calendarOpen, setCalendarOpen] = useState(false);

  function range(start, end, step = 1) {
    const length = Math.floor((end - start) / step) + 1;
    return Array.from({ length }, (_, index) => start + index * step);
  }

  const getYear = date => date.getFullYear();
  const getMonth = date => date.getMonth();

  const currentYear = getYear(new Date());
  const years = range(2012, currentYear + 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const CustomDatePickerInput = forwardRef(({ value, onClick }, ref) => (
    <div className="datepicker-input-container">
      <input
        className='form-control'
        type="text"
        value={value}
        onClick={() => {setCalendarOpen(true)}}
        ref = {ref}
        placeholder='date: mm/dd/yyyy'
        readOnly
        id='calendarInput'
        style={{height:'40px'}}
      />
      <img className='calendar-icon' src = {calendarIcon} alt = "icon"></img>
    </div>
  ));

  return (
    <DatePicker
    open={calendarOpen}
    onClickOutside={() => setCalendarOpen(false)}
    selected={selectedDate}
    onChange={(date) => setSelectedDate(date)}
    customInput={<CustomDatePickerInput />}
    shouldCloseOnSelect={false}
    renderCustomHeader={({
      date,
      changeYear,
      changeMonth,
      decreaseMonth,
      increaseMonth,
      decreaseYear,
      increaseYear,
      prevMonthButtonDisabled,
      nextMonthButtonDisabled,
    }) => (
      <div
        style={{
          margin: 10,
          display: "flex",
          justifyContent: "center",
          width:'100%'
        }}
      >
        <button className='arrow-button' onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
          {"<"}
        </button>
        <select className='select-button'
          value={months[getMonth(date)]}
          onChange={({ target: { value } }) =>
            changeMonth(months.indexOf(value))
          }
        >
          {months.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <button className='arrow-button' onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
          {">"}
        </button>

        <button className='arrow-button' onClick={decreaseYear} >
          {"<"}
        </button>
        <select className='select-button'
          value={getYear(date)}
          onChange={({ target: { value } }) => changeYear(value)}
        >
          {years.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>

        <button className='arrow-button' onClick={increaseYear}>
          {">"}
        </button>
      </div>
    )}
  >
  <div className="row">
    <div className="col-sm-3">
      <button className='text-button' onClick={() =>{setSelectedDate("")}} disabled = {selectedDate === null ? true : false}>clear</button>
    </div>
    <div className="col-sm-4"></div>
    <div className="col-sm-3">
        <button className='text-button' onClick={() =>{setCalendarOpen(false)}}>cancel</button>
    </div>
    <div className="col-sm-2">
      <button className='text-button' onClick={() =>{setSelectedDate(selectedDate); setCalendarOpen(false)}}>ok</button>
    </div>
  </div>
  </DatePicker>
  )
}

export default Calendar;