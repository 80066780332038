import React from 'react'
import './waterUseCard.css'
// import ellipseIcon from '../../../Icons/ellipse.svg'

function WaterUseCard(props) {
  return (
    <div className="card">
        <div className="card-body">
            <div className="row">
                <div className="col-sm-6 left">
                    <h3 className="innerCard-title-fade">
                        current month
                    </h3> 
                    <h3 className="innerCard-title-fade">
                        {props.month}
                    </h3> 
                    <h3 className="innerCard-title-bold">
                        water used
                    </h3>
                    {props.type === "volume"? 
                    <h3 className="inner-card-description">
                        (cubic meter m<sup>3</sup>)
                    </h3> :
                    <h3 className="inner-card-description">
                        (cost, yen)
                    </h3>
                    }
                </div>
                <div className="col-sm-6 right" style={{display:'flex', flexDirection:'column'}}>
                    {/* <img className='inner-card-icon' src={ellipseIcon} alt = "dot icon"></img> */}
                    <h3 className="inner-cardVal">
                      {props.value}
                    </h3>

                    {props.type === "volume" ? 
                    <h3 className="inner-cardUnit">
                        m<sup>3</sup>
                    </h3>:
                    <h3 className="inner-cardUnit">
                        &#165;
                    </h3>
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default WaterUseCard