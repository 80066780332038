import React from 'react';
import {Link} from 'react-router-dom';
import './loginPage.css'
import LoginForm from './Sections/LoginForm';
import logo from '../../Icons/logo.svg'


function LogInPage() {
    return (
    <div className='login-page' id = "login-page">
        <div className="row" style={{width:'500px', float:'left'}}>
            <div className="col-sm-5">
                <img className="company-logo"src = {logo} alt = "logo"></img>
            </div>
        </div>
        <LoginForm/>
        <h3 className='footerRow'>
            dont have an account?
            <Link to = "/register" className='footer-link-signup'>Sign up</Link>
        </h3>
        <h3 className="footerRow">
           <Link className='footer-link'>© dg takano</Link>
         · <Link className='footer-link'>Contact</Link>
         · <Link className='footer-link'>Privacy & terms</Link>
        </h3>
    </div>
    );
}

export default LogInPage